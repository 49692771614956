<template>
  <v-main>
    <v-card dark color="black d-flex fixed-header">
      <v-btn
        v-if="!this.isAdmin"
        @click="redirectToDashboard"
        style="background: #d5de20"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          style="fill: rgba(255, 255, 255)"
        >
          <path
            d="M21 11H6.414l5.293-5.293-1.414-1.414L2.586 12l7.707 7.707 1.414-1.414L6.414 13H21z"
          ></path>
        </svg>
        Back
      </v-btn>
      <v-card-text class="white--text">Support Chat</v-card-text>
    </v-card>
    <div ref="chatBody">
      <v-col class="totop">
        <div
          v-for="(item, index) in chat"
          :key="index"
          :class="[
            'd-flex flex-row align-center my-6',
            item.from === 'admin' && isAdmin ? 'justify-end' : null,
            item.from !== 'admin' && !isAdmin ? 'justify-end' : null,
          ]"
        >
          <div
            v-bind:class="{ day: item.day !== '' }"
            v-if="item.from !== 'admin'"
          >
            {{ item.day }}
          </div>
          <div
            v-if="item.from !== 'admin'"
            :class="[
              'message',
              isAdmin && item.from !== 'admin'
                ? 'message_left'
                : 'message_right',
              'max-width-75-per',
            ]"
          >
            <div class="wrap-message">
              <span v-if="!/(http(s?)):\/\//i.test(item.msg)">{{
                item.msg
              }}</span>
              <a v-else class="white--text" :href="item.msg" target="_blank">{{
                item.msg
              }}</a>
            </div>
            <ImageInChatMessage
              :images="item.images"
              @showImagePreview="showImagePreview"
            />
            <VoiceMsg :voiceMsg="item.voiceMsg" />
            <div class="time">{{ item.time }}</div>
          </div>
          <v-avatar
            :color="item.from !== 'admin' ? 'black' : 'black'"
            :class="[
              'mx-3',
              isAdmin && item.from === 'admin' ? 'order-last' : null,
              isAdmin && item.from !== 'admin' ? 'order-first' : null,
            ]"
            size="48"
          >
            <span class="white--text">{{ item.from }}</span>
          </v-avatar>

          <div
            v-if="item.from === 'admin'"
            :class="[
              'message',
              isAdmin && item.from === 'admin'
                ? 'message_right'
                : 'message_left',
            ]"
          >
            <span v-if="!/(http(s?)):\/\//i.test(item.msg)">{{
              item.msg
            }}</span>
            <a v-else class="white--text" :href="item.msg" target="_blank">{{
              item.msg
            }}</a>
            <ImageInChatMessage
              :images="item.images"
              @showImagePreview="showImagePreview"
            />
            <VoiceMsg :voiceMsg="item.voiceMsg" />
            <div class="time">{{ item.time }}</div>
          </div>
          <div
            v-bind:class="{ day: item.day !== '' }"
            v-if="item.from == 'admin'"
          >
            {{ item.day }}
          </div>
        </div>
      </v-col>
    </div>
    <div ref="placeholder" class="placeholder"></div>
    <v-card
      tile
      :class="[
        'justify-center  fixed-footer',
        !this.isAdmin ? 'width-100' : 'width-75',
      ]"
    >
      <div class="ma-0 pa-0">
        <v-row no-gutters>
          <v-col>
            <ImagesPreview
              :imagesPreview="imagesPreview"
              @deleteAttachedImage="deleteAttachedImage"
              @showImagePreview="showImagePreview"
            />
            <VoicePreview
              :recordTimer="recordTimer"
              :isRecording="isRecording"
              :isVoice="voiceMessage"
              @deleteVoice="deleteVoiceRecording"
            />
            <div class="d-flex flex-row align-center justify-center">
              <v-text-field
                autocomplete="off"
                v-model="msg"
                autofocus
                placeholder="Type Something"
                @keypress.enter="send"
                @paste="pasteImage"
                color="black"
                @click="scrollDown"
                @change="scrollDown"
              ></v-text-field>
              <v-btn icon class="ml-4" @click="send">
                <v-img :src="imgSend" style="width: 15px"></v-img>
              </v-btn>
              <file-selector
                accept-extensions=".jpg,.jpeg,.svg,.png"
                :multiple="true"
                :max-file-size="10 * 1024 * 1024"
                @changed="addImages"
                @validated="validateHandler"
              >
                <v-btn icon class="ml-4">
                  <v-img :src="imgFile"></v-img>
                </v-btn>
              </file-selector>
              <v-btn
                icon
                class="ml-4"
                @mousedown="startVoiceRecording"
                @mouseup="stopVoiceRecording"
                @touchstart="startVoiceRecording"
                @touchend="stopVoiceRecording"
                @contextmenu.prevent.stop="() => {}"
              >
                <v-img :src="imgVoice"></v-img>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <v-dialog
      v-model="dialog"
      content-class="hide-shadow"
      width="auto"
      elevation="0"
    >
      <v-carousel height="auto" v-model="model" hide-delimiters>
        <v-carousel-item v-for="(img, index) in imagesMsgPreview" :key="index">
          <v-img contain width="auto" height="auto" :src="img" max-height="700">
          </v-img>
        </v-carousel-item>
      </v-carousel>
    </v-dialog>
  </v-main>
</template>

<script>
import { mapState, mapActions } from "vuex";

import VoicePreview from "./VoicePreview";
import ImageInChatMessage from "./ImageInChatMessage";
import VoiceMsg from "./VoiceMsg";
import ImagesPreview from "./ImagesPreview";
import imgFile from "../../assets/images/chat/chat-file-icon.svg";
import imgVoice from "../../assets/images/chat/chat-voice-icon.svg";
import imgSend from "../../assets/images/chat/send.png";
import moment from "moment";

export default {
  name: "Chat",
  props: {
    isAdmin: {
      type: Boolean,
    },
    conversationId: {
      type: Number,
    },
  },
  components: { VoicePreview, ImageInChatMessage, VoiceMsg, ImagesPreview },
  data: () => ({
    imgFile,
    imgVoice,
    imgSend,
    userName: "",
    chat: [],
    msg: "",
    images: [],
    videos: [],
    imagesPreview: [],
    imagesMsgPreview: [],
    mediaRecorder: undefined,
    audioChunks: [],
    isMicroAvailable: false,
    overlayImageSrc: "",
    showOverlay: false,
    overlayZIndex: 10,
    showInfoOverlay: false,
    voiceMessage: null,
    voiceFile: undefined,
    isPastedImages: false,
    firstName: "",
    loaded: false,
    model: 0,
    dialog: false,
    eventSource: null,
    isRecording: false,
    recordTimer: "00:00:00",
    stream: null,
    timer: null,
    last: "",
  }),
  computed: {
    ...mapState(["userId"]),
    messageUrl() {
      return this.isAdmin
        ? `/chat/messages/${this.conversationId}`
        : `/chat/messages`;
    },
  },
  watch: {
    conversationId(val) {
      val = this.isAdmin ? val : null;
      this.loadConversation(val);
    },
  },
  created() {
    this.loadConversation(this.isAdmin ? this.conversationId : null);
  },
  updated() {
    if (this.isPastedImages) {
      this.msg = "";
      this.isPastedImages = false;
    }
  },
  methods: {
    ...mapActions(["setNotification"]),

    stopwatch() {
      let milliSecound = 0;
      const timeStart = () => {
        milliSecound = 0;
        clearInterval(this.timer);
        this.timer = setInterval(() => {
          milliSecound += 10;
          let dateTimer = new Date(milliSecound);
          this.recordTimer =
            ("0" + dateTimer.getUTCHours()).slice(-2) +
            ":" +
            ("0" + dateTimer.getUTCMinutes()).slice(-2) +
            ":" +
            ("0" + dateTimer.getUTCSeconds()).slice(-2);
        }, 10);
      };
      return timeStart();
    },

    subscribeToMercure(username) {
      let url = new URL(this.$vars.MERCURE_BASE_URL);
      url.searchParams.append("topic", "/chat/" + username);
      this.eventSource = new EventSource(url, {
        withCredentials: true,
      });
      this.eventSource.onmessage = async (event) => {
        const newMessage = JSON.parse(event.data);
        const message = {
          from: newMessage.is_admin ? "admin" : newMessage.user.first_name,
          msg: newMessage.content,
          time: moment().format("h:mm a"),
          day:
            moment().format("MMMM Do") == this.last
              ? ""
              : moment().format("MMMM Do"),
          images: [],
          voiceMsg: "",
        };
        this.last = moment().format("MMMM Do");
        if (
          (this.isAdmin && message.from !== "admin") ||
          (!this.isAdmin && message.from === "admin")
        )
          await this.GoogleNotify();

        if (newMessage.files) {
          newMessage.files.forEach((file) => {
            if (file.file.includes(".webm") || file.file.includes(".oga")) {
              message.voiceMsg = this.$vars.BACKEND_BASE_URL + file.file;
            } else {
              message.images.push(this.$vars.BACKEND_BASE_URL + file.file);
              setTimeout(() => {
                this.scrollDown();
              }, 500);
            }
          });
        }
        this.chat.push(message);

        await this.$nextTick(() => {
          this.scrollDown();
        });
      };
    },
    redirectToDashboard() {
      this.$router.push({ name: "dashboard" });
    },
    send() {
      this.sendToServer();
      this.clear();
    },
    scrollDown() {
      this.$refs.placeholder.scrollIntoView();
    },
    clear() {
      this.msg = "";
      this.images = [];
      this.imagesPreview = [];
      this.imagesMsgPreview = [];
      this.voiceMessage = null;
      this.voiceFile = null;
    },
    deleteAttachedImage(index) {
      this.images.splice(index, 1);
      this.imagesPreview.splice(index, 1);
    },
    showImagePreview({ index, images }) {
      this.overlayImageSrc = images[index];
      this.dialog = true;
      this.imagesMsgPreview = images;
      this.model = index;
    },

    startVoiceRecording() {
      if (navigator?.mediaDevices) {
        this.isRecording = true;
        navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
          this.isMicroAvailable = true;
          this.mediaRecorder = new MediaRecorder(stream);
          this.mediaRecorder.start();

          this.audioChunks = [];

          this.mediaRecorder.addEventListener("dataavailable", (event) => {
            this.audioChunks.push(event.data);
          });
        });
      }
      this.stopwatch();
    },
    stopVoiceRecording() {
      this.mediaRecorder?.stream.getTracks().forEach((track) => track.stop());
      this.isRecording = false;
      this.recordTimer = "00:00:00";
      if (this.isMicroAvailable) {
        this.mediaRecorder.stop();
        this.voiceMessage = null;

        this.mediaRecorder.addEventListener("stop", () => {
          const audioBlob = new Blob(this.audioChunks, {
            type: "audio/webm; codecs=opus",
          });
          this.voiceFile = new File([audioBlob], "voice", {
            type: "audio/webm; codecs=opus",
          });
          this.voiceMessage = URL.createObjectURL(this.voiceFile);
          console.log(this.voiceMessage);
        });
      }
    },
    deleteVoiceRecording() {
      this.voiceMessage = null;
    },
    GoogleNotify() {
      if (!("Notification" in window)) {
        return;
      } else if (Notification.permission === "granted") {
        const audio = new Audio(
          this.$vars.BACKEND_BASE_URL + "/public/files/notification.mp3"
        );
        audio.play();
        navigator.serviceWorker.ready.then(function (registration) {
          registration.showNotification("you have a new message");
        });
        // ServiceWorkerRegistration.showNotification("you have a new message");
        // new Notification("rowseryou have a new message");
      } else if (Notification.permission !== "denied") {
        Notification.requestPermission(function (permission) {
          if (permission === "granted") {
            new Notification("you have a new message");
          }
        });
      }
    },
    validateHandler(value) {
      console.log("value", value);
      if (value === "FILE_SIZE_ERROR") {
        this.setNotification({
          show: true,
          type: "error",
          message: "This image is too large",
        });
      }
    },

    async addPreviewImage(image) {
      const reader = new FileReader();
      await reader.readAsDataURL(image);
      reader.onload = () => {
        this.imagesPreview.push(reader.result);
      };
    },
    async loadConversation(id) {
      this.chat = [];
      try {
        const response = id
          ? await this.$axios.get(`/chat/conv/${id}`)
          : await this.$axios.get("/chat/conv");

        if (response.status === 200) {
          this.firstName = response.data.conversation.user.first_name;
          this.username = response.data.conversation.user.username;
          response.data.messages.forEach((item) => {
            const message = {
              from: item.is_admin
                ? "admin"
                : response.data.conversation.user.first_name,
              msg: item.content,
              time: moment(item.datetime * 1000).format("h:mm a"),
              day: moment(item.datetime * 1000).format("MMMM Do"),
              images: [],
              voiceMsg: "",
            };
            if (item.files) {
              item.files.forEach((file) => {
                if (file.file.includes(".webm") || file.file.includes(".oga")) {
                  message.voiceMsg = this.$vars.BACKEND_BASE_URL + file.file;
                } else {
                  message.images.push(this.$vars.BACKEND_BASE_URL + file.file);
                }
              });
            }
            this.chat.push(message);

            for (var i = 0; i < this.chat.length; i++) {
              if (this.last == "") {
                this.last = this.chat[i].day;
              } else if (this.chat[i].day == this.last && i != 0) {
                this.chat[i].day = "";
              } else if (
                this.chat[i].day != this.last &&
                this.chat[i].day != ""
              ) {
                this.last = this.chat[i].day;
              }
            }
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        await this.$nextTick();
        this.loaded = true;
        // console.log(this.eventSource, 'this.eventSource');
        if (this.eventSource) {
          this.eventSource.close();
        }
        await this.subscribeToMercure(this.username);
        await this.scrollDown();
      }
    },
    async sendToServer() {
      try {
        const payload = new FormData();

        payload.append("message", this.msg);
        payload.append("userId", this.userId);

        if (this.images.length) {
          this.images.forEach((image, index) => {
            payload.append(`images[${index}]`, this.images[index]);
          });
        }
        // if (this.videos.length) {
        //   this.videos.forEach((video, index) => {
        //     payload.append(`videos[${index}]`, this.videos[index])
        //   })
        // }

        if (this.voiceMessage) {
          payload.append("voice", this.voiceFile);
        }

        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };

        await this.$axios.post(`${this.messageUrl}`, payload, config);
      } catch (error) {
        console.error(error);
      }
    },
    async addImages(files) {
      if (files.length > 10) {
        this.setNotification({
          show: true,
          type: "error",
          message: "image limit exceeded",
        });
        return;
      }

      this.images = [...files].filter((file) => file.type.includes("image"));
      console.log("this.images", this.images);
      // this.videos = [...files].filter(file => file.type.includes('video'))
      // console.log('this.videos', this.videos);
      this.imagesPreview = [];
      for (let i = 0; i < this.images.length; i++) {
        await this.addPreviewImage(this.images[i]);
      }
    },
    async pasteImage(event) {
      const items = event.clipboardData.items;
      for (const item of items) {
        const blob = item.getAsFile();
        if (blob) {
          this.isPastedImages = true;
          this.images.push(blob);
          await this.addPreviewImage(blob);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/styles/variables";

.time {
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: 0;
  color: black;
  font-size: 12px;
  @media screen and (height: 1280px) and (width: 800px) {
    font-size: 10px;
  }
}
.day {
  display: flex;
  position: absolute;
  margin-top: -90px;
  border: 2px solid #000;
  background-color: #000;
  color: #d5de20;
  border-radius: 15px;
  padding: 2px;
  left: 50%;
  transform: translate(-50%);
  z-index: 1;
  @media screen and (max-width: 1000px) {
    font-size: 12px;
    margin-top: -105px;
    padding: -2px 5px;
    border: none;
  }
}
.message {
  color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-end;
  text-align: start;
  padding: 12px;
  background-color: $primary;
  position: relative;
  min-width: 62px;
  min-height: 67px;
  height: 100%;
  border-radius: 34px !important;

  &:before {
    content: "";
    position: absolute;
    display: block;
    width: 37px;
    height: 18px;
    bottom: 0;
  }

  &_left {
    border-radius: 10px 10px 10px 0;

    &:before {
      left: -8px;
      background: linear-gradient(to top left, $primary 50%, transparent 50%);
    }
  }

  &_right {
    border-radius: 10px 10px 0 10px;

    &:before {
      right: -8px;
      background: linear-gradient(to top right, $primary 50%, transparent 50%);
    }
  }
}

.w-100 {
  width: 100%;
}

.max-width-75-per {
  max-width: 75%;
}

.wrap-message {
  max-width: 100%;
  overflow-wrap: break-word;
  hyphens: auto;
}

.close-overlay-button {
  z-index: 10;
  background: #3f3f3f;
}

.delete-attached-image {
  z-index: 10;
  transform: translate(25%, -50%);
}

.width-75 {
  width: 75%;
}

.width-100 {
  width: 100%;
}

.fixed-footer {
  position: fixed;
  bottom: 0;
  z-index: 10;
  margin-top: -70px;
}

.fixed-header {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 2;
  align-items: center;
}

.placeholder {
  min-height: 1px;
  margin-top: 1px;
  margin-bottom: 100px;
}
</style>
