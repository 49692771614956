<template>
  <div v-if="imagesPreview.length" class="d-flex flex-row justify-start mt-3">
    <div
      v-for="(imagePreview, index) in imagesPreview"
      :key="imagePreview"
      class="mr-3"
    >
      <v-btn
        class="delete-attached-image"
        absolute
        icon
        x-small
        @click="deleteAttachedImage(index)"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-img
        :src="imagePreview"
        max-width="50"
        max-height="100"
        @click="showImagePreview({ index, images: imagesPreview })"
      ></v-img>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImagesPreview",
  props: {
    imagesPreview: {
      type: Array,
    },
  },
  methods: {
    deleteAttachedImage(index) {
      this.$emit("deleteAttachedImage", index);
    },
    showImagePreview(obj) {
      this.$emit("showImagePreview", obj);
    },
  },
};
</script>

<style lang="scss" scoped>
.delete-attached-image {
  z-index: 10;
  transform: translate(25%, -50%);
}
</style>
