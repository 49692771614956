<template>
  <div>
    <p v-if="isRecording">
      <span class="mr-6">{{ recordTimer }}</span
      >Recording Voice
    </p>
    <div v-if="this.isVoice" class="d-flex align-center justify-start">
      <audio
        @ended="endVoice"
        @canplaythrough="startTime"
        @timeupdate="progressBar"
        ref="track"
        :src="this.isVoice"
      ></audio>
      <span ref="tracktime" class="tracktime">
        0{{
          Math.floor(this.currentTime / 60) +
          `:${Math.floor(this.currentTime % 60) >= 10 ? "" : "0"}` +
          Math.floor(this.currentTime % 60)
        }}
        / 0{{
          Math.floor(this.duration / 60) +
          `:${Math.floor(this.duration % 60) >= 10 ? "" : "0"}` +
          Math.floor(this.duration % 60)
        }}</span
      >
      <div class="w-100 flex-5" style="min-height: 15px">
        <v-progress-linear
          @change="changeTime"
          rounded
          class="audio"
          color="primary"
          v-model="barValue"
          ref="prog"
          height="12"
        ></v-progress-linear>
      </div>
      <v-icon @click="playVoice" color="primary">{{
        `mdi-${isPlaying ? "pause" : "play"}`
      }}</v-icon>
      <v-btn icon @click="deleteVoice">
        <v-icon color="primary">mdi-delete</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
// import {VuetifyAudio} from 'vuetify-audio'
export default {
  name: "VoicePreview",
  props: {
    isVoice: {
      type: String,
    },
    isRecording: {
      type: Boolean,
    },
    recordTimer: {
      type: String,
      default: "00:00:00",
    },
  },
  // components: {VuetifyAudio},
  data: () => ({
    interval: 0,
    duration: 0,
    currentTime: 0,
    isPlaying: false,
  }),
  computed: {
    barValue() {
      return (this.currentTime / this.duration) * 100;
    },
  },
  methods: {
    endVoice() {
      console.log("end");
      this.currentTime = 0;
      this.isPlaying = false;
    },
    playVoice() {
      if (!this.isPlaying) {
        this.$refs.track.play();
        this.isPlaying = true;
      } else {
        this.$refs.track.pause();
        this.isPlaying = false;
      }
    },
    progressBar() {
      this.currentTime = this.$refs.track.currentTime;
    },
    startTime() {
      this.getDuration().then((duration) => {
        this.duration = duration;
      });
    },
    changeTime(percent) {
      this.$refs.track.currentTime = (this.duration / 100) * percent;
    },
    getDuration() {
      return new Promise((resolve) => {
        let a = new Audio(this.isVoice);
        if (a.duration === Infinity || isNaN(a.duration)) {
          a.currentTime = 1e6;
          const interval = window.setInterval(() => {
            if (a.duration !== Infinity && !isNaN(a.duration)) {
              resolve(a.duration);
              a = null;
              window.clearInterval(interval);
            }
          }, 20);
        } else {
          resolve(a.duration);
        }
      });
    },
    deleteVoice() {
      this.$emit("deleteVoice");
    },
  },
};
</script>

<style lang="scss" scoped>
.w-100 {
  width: 100%;
}

//audio::-webkit-media-controls-time-remaining-display {
//  display: block !important;
//}

.audio {
  width: 100%;
  height: 140px;

  & .v-progress-linear {
    display: none !important;
  }
}

.tracktime {
  display: flex;
  align-content: center;
  width: 150px;
}
</style>
