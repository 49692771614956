<template>
  <div
    v-if="this.voiceMsg"
    class="w-100 wrap-voice d-flex flex-row align-center"
  >
    <audio
      @ended="endVoice"
      @canplaythrough="startTime"
      @timeupdate="progressBar"
      ref="track"
      :src="this.voiceMsg"
    ></audio>
    <v-icon @click="playVoice" color="white">{{
      `mdi-${isPlaying ? "pause" : "play"}`
    }}</v-icon>
    <div class="d-flex flex-column justify-center">
      <div class="w-100 flex-5" style="min-height: 15px">
        <v-progress-linear
          @change="changeTime"
          rounded
          class="audio ml-2 mr-8"
          color="white"
          v-model="barValue"
          ref="prog"
          height="10"
        ></v-progress-linear>
      </div>
      <span ref="tracktime" class="mr-2 ml-auto tracktime">
        0{{
          Math.floor(this.currentTime / 60) +
          `:${Math.floor(this.currentTime % 60) >= 10 ? "" : "0"}` +
          Math.floor(this.currentTime % 60)
        }}
        / 0{{
          Math.floor(this.duration / 60) +
          `:${Math.floor(this.duration % 60) >= 10 ? "" : "0"}` +
          Math.floor(this.duration % 60)
        }}</span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "VoiceMsg",
  data: () => ({
    interval: 0,
    duration: 0,
    currentTime: 0,
    isPlaying: false,
  }),
  props: {
    voiceMsg: {
      type: String,
    },
  },
  computed: {
    barValue() {
      return (this.currentTime / this.duration) * 100;
    },
  },
  methods: {
    endVoice() {
      console.log("end");
      this.currentTime = 0;
      this.isPlaying = false;
    },
    playVoice() {
      if (!this.isPlaying) {
        this.$refs.track.play();
        this.isPlaying = true;
      } else {
        this.$refs.track.pause();
        this.isPlaying = false;
      }
    },
    progressBar() {
      this.currentTime = this.$refs.track.currentTime;
    },
    startTime() {
      this.getDuration().then((duration) => {
        this.duration = duration;
      });
    },
    changeTime(percent) {
      this.$refs.track.currentTime = (this.duration / 100) * percent;
    },
    getDuration() {
      return new Promise((resolve) => {
        let a = new Audio(this.voiceMsg);
        if (a.duration === Infinity || isNaN(a.duration)) {
          a.currentTime = 1e6;
          const interval = window.setInterval(() => {
            if (a.duration !== Infinity && !isNaN(a.duration)) {
              resolve(a.duration);
              a = null;
              window.clearInterval(interval);
            }
          }, 20);
        } else {
          resolve(a.duration);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.tracktime {
  transform: translateY(10px);
}

.audio-size {
  max-width: 200px;
}

.audio {
  transform: translateY(14px);
  min-width: 100px;

  @media screen and (min-width: 600px) {
    min-width: 280px;
    max-width: 300px;
    cursor: pointer;
  }
}
</style>
