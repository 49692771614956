<template>
  <div
    v-if="images"
    class="d-flex justify-end flex-wrap"
    :class="{ chatWithImg: images }"
  >
    <v-img
      onload
      v-for="(image, index) in images"
      :key="image"
      class="ml-2 mb-2"
      :src="image"
      width="100"
      max-width="150"
      max-height="200"
      style="cursor: pointer"
      @click="showImagePreview({ index, images })"
    ></v-img>
  </div>
</template>

<script>
export default {
  name: "ImageInChatMessage",
  props: {
    images: {
      type: Array,
    },
  },
  data: () => ({}),
  methods: {
    showImagePreview(image) {
      this.$emit("showImagePreview", image);
    },
  },
};
</script>

<style lang="scss" scoped>
.chatWithImg {
  max-width: 400px;
}

.hide-shadow {
  box-shadow: none !important;
}
</style>
